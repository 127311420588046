
import "./App.scss"
import Narbar from "./Narbar";



import Seccion1 from "./Pages/Seccion1";
import Seccion2 from "./Pages/Seccion2";
import Seccion3 from "./Pages/Seccion3";
import Seccion4 from "./Pages/Seccion4";
import Seccion5 from "./Pages/Seccion5";
import Seccion6 from "./Pages/Seccion6";
import Seccion7 from "./Pages/Seccion7";
import Seccion8 from "./Pages/Seccion8";
import Seccion9 from "./Pages/Seccion9";

function App() {
  return (
    <div className="App">
      <div className="nav">
        <Narbar/>
      </div>
      
      <div className="Seccion1">
        <Seccion1/>
      </div>
      <div className="Seccion2">
        <Seccion2/>
      </div>
      <div className="Seccion3">
        <Seccion3/>
      </div>
      <div className="Seccion4">
        <Seccion4/>
      </div>
      <div className="Seccion5">
        <Seccion5/>
      </div>
      <div className="Seccion6">
        <Seccion6/>
      </div>
      <div className="Seccion7">
        <Seccion7/>
      </div>
      <div className="Seccion8">
        <Seccion8/>
      </div>
      <div className="Seccion9">
        <Seccion9/>
      </div>
    </div>
  );
}

export default App;
