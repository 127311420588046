/* eslint-disable jsx-a11y/alt-text */
import koky from "../Img/koky.png"
import "../Scss/seccion1.scss"


const Seccion1 = () => {
    return(
        <section className="Sec1" id="bio">
            <div className="Contenido1">
                <h1 className="nombre"><br/>JORGE ALEMAN</h1>
                <h4 className="desp">
                Soy un diseñador gráfico independiente con una gran pasión por la creatividad, 
                la fotografía, la creación de contenidos y estrategias funcionales para emprendimientos, 
                negocios y empresas. Mi objetivo es proporcionar soluciones personalizadas y efectivas que 
                ayuden a mis clientes a destacarse en su mercado y alcanzar sus objetivos comerciales. 
                Abordo cada proyecto con entusiasmo y compromiso, trabajando en estrecha colaboración con 
                mis clientes para comprender sus necesidades y crear soluciones que se ajusten a su visión y objetivos.
                </h4>
            </div> 
            <div className="Contenido2">
                
                <img src={koky}/>
              
            </div>
        </section>
    )
}

export default Seccion1