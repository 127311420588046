import "../Scss/seccion2.scss"


const Seccion2 = () => {
    return(
        <section className="Sec2" id="portafolio">
            <div className="Contenido">
                <h1 className="titulo"><br/>COMMUNITY MANAGER</h1>
                <h4 className="desc">
                Como responsable de la gestión de la presencia en redes sociales de una empresa, 
                negocio o emprendimiento, mi objetivo es proporcionar una serie de servicios que 
                aseguren una presencia sólida y efectiva en las plataformas más relevantes, como 
                Facebook e Instagram. En este sentido, trabajo para crear y difundir contenido 
                original y relevante que se adapte a las necesidades y características de la 
                comunidad de la marca como:
                </h4>
                <div className="list">
                    <h4>
                    • Planifico y ejecuto estrategias de social media que 
                    aseguren el máximo impacto en el público objetivo
                    </h4>
                    <h4>
                    • Me encargo de monitorear y moderar la interacción de los usuarios con la marca en línea
                    </h4>
                    <h4>
                    • Llevo a cabo análisis de resultados y generación de informes detallados acerca del desempeño 
                        de las acciones llevadas en el 
                        ámbito de la presencia en redes sociales
                    </h4>
                </div>
                <h4 className="descc">
                En resumen, mi enfoque es asegurar una 
                presencia sólida, relevante y efectiva de la marca en las redes sociales, a través 
                de una gestión completa y detallada de todas las acciones llevadas en este ámbito.
                </h4>
                <p>ㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤ</p>
            </div>
        </section>
    )
}

export default Seccion2