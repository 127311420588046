/* eslint-disable jsx-a11y/alt-text */
import IMG1 from "../Img/img1.png"
import IMG2 from "../Img/img2.png"
import "../Scss/seccion4.scss"


const Seccion4 =()=>{
    return(
        <section className="Sec4">
            <div className="Cont1">
                <h1 className="tituloo"><br/>PUBLICACIONES EN REDES SOCIALES<br/><br/></h1>
                <img className="imgg" height={280} width={230}  src={IMG1}/>
            </div>
            <div className="Cont2">
                <h1 className="tituloo"><br/>TARJETAS DE PRESENTACIÓN<br/><br/></h1>
                <img className="imgg" src={IMG2}/>
                <br/><br/>
            </div>
        </section>
    )
}


export default Seccion4