import "../Scss/seccion7.scss"


const Seccion7 =()=>{
    return(
        <section className="Sec7" id="servicios">
            <div className="contenido">
                <h1 className="titulo"><br/>SERVICIOS<br/><br/></h1>
                <p className="text1">
                    -GESTIÓN DE REDES SOCIALES<br/>
                    -FOTOGRAFÍA Y VIDEOS<br/>
                    -REALIZACIÓN DE TOMAS DE DRON<br/>
                    -DISEÑOS DE PUBLICIDAD<br/>
                    -DISEÑOS DE LOGOTIPOS, ISOTIPOS, IMAGOTIPOS E ISOLOGOS<br/>
                    -EDICIÓN DE FOTOS O VIDEOS
                </p>
                <p className="text2">
                -ELABORACIÓN DE CALENDARIOS DE CONTENIDO DE LA EMPRESA O NEGOCIO<br/>
                -ATENCIÓN A LOS USUARIOS: RESPONDER COMENTARIOS, MENSAJES PRIVADOS, OPINIONES<br/>
                -ANALIZAR Y PRESENTAR INFORMES DEL CONTROL Y CRECIMIENTO DE LA PÁGINA

                </p>
            </div>
            <br/><br/>
        </section>
    )
}

export default Seccion7