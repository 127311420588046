/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";
import {
  Container,
  Wrapper,
  IconContainer,
  Menu,
  MenuItem,
  MenuItemLink,
  MobileMenuIcon,
} from "./Navbar.elements";
import "./navbar.scss"
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons";
import IMG from "./Img/Logo.png"

const Narbar = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleShowMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };
  return (
    <>
      <Container>
        <Wrapper>
          <IconContext.Provider value={{ color: "#eee", size: "1.8em" }}>
            <IconContainer showMobileMenu={showMobileMenu}>
            <div className="IMG">
              <img class="img" height={50} width={50} src={IMG}/>
              </div> 
            </IconContainer>

            <MobileMenuIcon onClick={() => handleShowMobileMenu()}>
              {showMobileMenu ? <FaTimes /> : <FaBars />}
            </MobileMenuIcon>

            <Menu showMobileMenu={showMobileMenu}>
              <MenuItem onClick={() => handleShowMobileMenu()}>
                <MenuItemLink href="#bio">BIO</MenuItemLink>
                <hr />
              </MenuItem>
              <MenuItem onClick={() => handleShowMobileMenu()}>
                <MenuItemLink href="#portafolio">PORTAFOLIO</MenuItemLink>
                <hr />
              </MenuItem>
              <MenuItem onClick={() => handleShowMobileMenu()}>
                <MenuItemLink href="#trabajos">TRABAJOS REALIZADOS</MenuItemLink>
                <hr />
              </MenuItem>
              <MenuItem onClick={() => handleShowMobileMenu()}>
                <MenuItemLink href="#servicios">SERVICIOS</MenuItemLink>
                <hr />
              </MenuItem>
              <MenuItem onClick={() => handleShowMobileMenu()}>
                <MenuItemLink href="#contactos">CONTACTO</MenuItemLink>
                <hr />
              </MenuItem>
            </Menu>
          </IconContext.Provider>
        </Wrapper>
      </Container>
    </>
  );
};

export default Narbar;