/* eslint-disable jsx-a11y/alt-text */
import "../Scss/seccion5.scss"
import IMG1 from "../Img/b1.png"
import IMG2 from "../Img/b2.png"
import IMG3 from "../Img/b3.png"


const Seccion5 =()=>{
    return(
        <section className="Sec5">
            <div className="Conte">
            <h1 className="titulo1"><br/>BANNERS</h1>
                <div className="IMG1">
                    <img height={400} width={180} src={IMG1}/>
                </div>
                <h1 className="titulo2"><br/>MICROPERFORADOS<br/><br/></h1>
                <div className="IMG2">
                    <img height={400} width={180} src={IMG2}/>
                    <br/><br/>
                </div>
                <h1 className="titulo3"><br/>STICKERS</h1>
                <div className="IMG3">
                    <img height={300} width={300} src={IMG3}/><br/><br/>
                </div>
            </div>
        </section>
    )
}

export default Seccion5