/* eslint-disable jsx-a11y/alt-text */
import IMG1 from "../Img/mockup1.png"
import IMG2 from "../Img/mockup2.png"
import IMG3 from "../Img/mockup3.png"
import "../Scss/seccion3.scss"

const Seccion3 = () => {
    return(
        <section className="Sec3">
            <h1 className="titulo"><br/>MOCKUPS<br/><br/></h1>
            <div className="Container1">
                <img className="img2" src={IMG2}/>
                <br/><br/>
            </div>
            <div className="Container2">
                <img className="img2" src={IMG1}/>
                <br/><br/>
            </div>
            <div className="Container3">
                <img className="img3" src={IMG3}/>
                <br/><br/>
            </div>
        </section>
    )
}

export default Seccion3