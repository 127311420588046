/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import "../Scss/seccion6.scss"
import Hemo from "../Img/hemolab.png"
import Mata from "../Img/matamoros.png"
import Pro from "../Img/pro.png"
import Sug from "../Img/sugar.png"
import Face from "../Img/face.png"
import Insta from "../Img/Insta2.png"



const Seccion6 =()=>{
    return(
        <section className="Sec6" id="trabajos">
            <div className="conten">
                <h1 className="titulo"><br/>TRABAJOS REALIZADOS<br/><br/></h1>
                <div className="Hemo">
                    <img src={Hemo} height={130} width={400}/>
                    <br/>
                    <a href="https://www.facebook.com/profile.php?id=100087957706043" target="_blank"><button className="btn-face"><img className="f1" src={Face} height={40} width={40}/></button></a>
                    <a href="https://www.instagram.com/hemolab__/" target="_blank"><button className="btn-insta"><img className="i1" src={Insta} height={40} width={40}/></button></a>
                    <br/>
                   
                    <br/><br/>
                </div>
                <div className="Mata">
                    <img src={Mata}/>
                    <br/>
                    <a href="https://www.facebook.com/profile.php?id=100090341230536" target="_blank"><button className="btn-face"><img className="f1" src={Face} height={40} width={40}/></button></a>
                    <a href="https://www.instagram.com/matamoros_quicklube/" target="_blank"><button className="btn-insta"><img className="i1" src={Insta} height={40} width={40}/></button></a>
                    <br/>
                    
                </div>
                <div className="Pro">
                <br/><br/>
                    <img src={Pro}/>
                    <br/>
                    <a href="https://www.instagram.com/l1feprosv/" target="_blank"><button className="btn-insta"><img className="i1" src={Insta} height={40} width={40}/></button></a>
                    <br/>
                    
                </div>
                <div className="Sugar">
                <br/><br/>
                    <img src={Sug}/>
                    <br/>
                    <a href="https://www.facebook.com/profile.php?id=100089621959966" target="_blank"><button className="btn-face"><img className="f1" src={Face} height={40} width={40}/></button></a>
                    <a href="https://www.instagram.com/suggarsv/" target="_blank"><button className="btn-insta"><img className="i1" src={Insta} height={40} width={40}/></button></a>
                    <br/>
                   
                </div>
                <br/><br/>
            </div>
        </section>
    )
}

export default Seccion6