/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import "../Scss/seccion9.scss"
import IMG from "../Img/Logo.png"
import Insta from "../Img/Insta2.png"



const Seccion9 =()=>{
    return(
        <section className="Sec9">
            <br/>
            <div className="conte">
                <br/>
                <img className="img" src={IMG} height={50} width={45}/>
                <p className="tex"><br/>Creamos tu estilo visual, único para ti e identificable para otros. ‎ ‎ ‎ ‎ | Siguenos</p>
                <a className="ins" href="https://www.instagram.com/p.r.i.n.t_sv/" target="_blank"><button className="btn-insta">
                    <img className="i1" src={Insta} height={30} width={30}/></button>
                </a>
            </div>
        </section>
    )
}

export default Seccion9