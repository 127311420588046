/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import "../Scss/seccion8.scss"



const Seccion8 =()=>{
    return(
        <section className="Sec8" id="contactos">
            <div className="conte">
                <h1 className="titulo"><br/>CONTACTO<br/><br/></h1>
                <div className="face">
                    <h1 className="nombre">JORGE ALEMAN</h1>
                    <br/>
                    <a href="https://www.facebook.com/jorge.aleman.5/" target="_blank"><button className="btn-face"><p>Facebook</p></button></a>
                </div>
                <br/>
                <div className="insta">
                    <h1 className="nombre">JORGE.AL_</h1>
                    <br/>
                    <a href="https://www.instagram.com/jorge.al_/" target="_blank"><button className="btn-insta"><p>Instagram</p></button></a>
                </div>
                <br/>
                <div className="whats">
                    <h1 className="nombre">JORGE ALEMAN</h1>
                    <br/>
                    <a href="https://wa.me/75024466?text=Hola%20Gorge,%20me%20gustaria%20saber%20más%20sobre%20los%20diferentes%20paquetes%20de%20servios%20y%20sus%20precios." target="_blank"><button className="btn-whats"><p>Whatsapp</p></button></a>
                </div>
            </div>
            <br/>
            <br/>
            <br/>
        </section>
    )
}

export default Seccion8